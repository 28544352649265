import type { ISponsor } from 'components/SponsorSection';

export interface ITheme {
  orange: string;
  backgroundOrange: string;
  headingSize: string;
  breakpoints: {
    wide: string;
    desktop: string;
    tablet: string;
    mobile: string;
  };
}

export const THEME: ITheme = {
  orange: '#F0A51B', // Usually used as a text color.
  backgroundOrange: '#FD9A00', // Usually used as a backgorund color.
  headingSize: '2.5rem',
  breakpoints: {
    wide: '(min-width: 1600px)',
    desktop: '(max-width: 1599px)',
    tablet: '(max-width: 1024px)',
    mobile: '(max-width: 576px)'
  }
};

// Sentry
export const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

// Video Player
export const STAGE_ONE_VIDEO_PLAYER_URL =
  process.env.NEXT_PUBLIC_STAGE_ONE_VIDEO_PLAYER_URL;
export const STAGE_TWO_VIDEO_PLAYER_URL =
  process.env.NEXT_PUBLIC_STAGE_TWO_VIDEO_PLAYER_URL;
export const STAGE_THREE_VIDEO_PLAYER_URL =
  process.env.NEXT_PUBLIC_STAGE_THREE_VIDEO_PLAYER_URL;

// Emails
export const EMAIL_API_KEY = process.env.NEXT_PUBLIC_EMAIL_API_KEY;
export const ARE_EMAILS_ENABLED =
  process.env.NEXT_PUBLIC_ARE_EMAILS_ENABLED === 'true';
export const FROM_EMAIL = process.env.NEXT_PUBLIC_FROM_EMAIL;
export const FROM_NAME = process.env.NEXT_PUBLIC_FROM_NAME;
export const SIMPLE_EMAIL_TEMPLATE_ID =
  process.env.NEXT_PUBLIC_SIMPLE_EMAIL_TEMPLATE_ID;
export const ADVANCED_EMAIL_TEMPLATE_ID =
  process.env.NEXT_PUBLIC_ADVANCED_EMAIL_TEMPLATE_ID;

// Data Sync
export const DATA_SYNC_SECRET = process.env.NEXT_PUBLIC_DATA_SYNC_SECRET;
export const DATA_SYNC_AUTH_COOKIE =
  process.env.NEXT_PUBLIC_DATA_SYNC_AUTH_COOKIE;
export const DATA_SYNC_EVENT_URL_BASE =
  process.env.NEXT_PUBLIC_DATA_SYNC_EVENT_URL_BASE;

export const ORGANIZING_PARTNERS: Array<ISponsor> = [
  { url: 'https://www.hacksoft.io/', img: '/images/sponsors/hacksoft.png' }
];

export const GOLD_SPONSORS: Array<ISponsor> = [
  { url: 'https://www.experian.bg/', img: '/images/sponsors/experian.png' },
  { url: 'https://www.rewe-digital.com/', img: '/images/sponsors/rewe.png' },
  {
    url: 'https://www.superhosting.bg/',
    img: '/images/sponsors/superhosting.png'
  },
  { url: 'https://www.progress.com/', img: '/images/sponsors/progress.png' },
  { url: 'https://strypes.eu/', img: '/images/sponsors/strypes.png' },
  {
    url: 'https://www.sap.com/bulgaria/about.html',
    img: '/images/sponsors/sap.png'
  },
  {
    url: 'https://bosch.io/about-us/locations/sofia/',
    img: '/images/sponsors/bosch.png'
  },
  { url: 'https://www.vmware.com/', img: '/images/sponsors/vmware.png' }
];

export const SILVER_PLUS_SPONSORS: Array<ISponsor> = [
  { url: 'https://www.halodx.com/', img: '/images/sponsors/halodx.png' },
  { url: 'https://nexo.careers/', img: '/images/sponsors/nexo.png' },
  {
    url: 'https://www.milestonesys.com/',
    img: '/images/sponsors/milestone.png'
  },
  {
    url: 'https://careers.paysafe.com/sofiaengineeringteams/',
    img: '/images/sponsors/paysafe.png'
  },
  {
    url: 'https://www.uber.com/us/en/careers/locations/sofia/',
    img: '/images/sponsors/uber.png'
  },
  {
    url: 'https://www.chaosgroup.com/',
    img: '/images/sponsors/chaos.png'
  },
  {
    url: 'https://amadeus.com/en',
    img: '/images/sponsors/amadeus.png'
  },
  { url: 'https://dreamix.eu/', img: '/images/sponsors/dreamix.png' },
  {
    url: 'https://www.softwareag.com/',
    img: '/images/sponsors/softwareag.png'
  },
  { url: 'https://www.launchee.space/', img: '/images/sponsors/launchee.png' },
  { url: 'https://devrix.com/', img: '/images/sponsors/devrix.png' }
];

export const SILVER_SPONSORS: Array<ISponsor> = [
  { url: 'https://programista.bg/', img: '/images/sponsors/programista.png' },
  { url: 'https://www.axway.com/en', img: '/images/sponsors/axway.png' },
  {
    url: 'https://www.greenbone.net/en/jobs-bulgaria/',
    img: '/images/sponsors/greenbone.png'
  },
  {
    url: 'https://www.scalefocus.com/',
    img: '/images/sponsors/scalefocus.png'
  },
  { url: 'http://www.hypoport.bg/', img: '/images/sponsors/hypoport.png' }
];

export const PARTNERS: Array<ISponsor> = [
  { url: 'https://www.puzl.com/', img: '/images/partners/puzl.png' },
  {
    url: 'https://www.telerikacademy.com/',
    img: '/images/partners/telerik.png'
  },
  { url: 'https://www.ablebulgaria.org/en/', img: '/images/partners/able.png' },
  { url: 'https://betahaus.bg/en/', img: '/images/partners/betahaus.svg' },
  { url: 'https://netpeak.bg/', img: '/images/partners/netpeak.png' },
  { url: 'http://thehub-aubg.com/', img: '/images/partners/thehub.png' },
  { url: 'https://careershow.bg/', img: '/images/partners/careershow.png' },
  { url: 'https://www.campusx.company/', img: '/images/partners/campusx.png' },
  {
    url: 'https://www.thesuperhumanpodcast.net/',
    img: '/images/partners/superhuman.png'
  },
  { url: 'https://dev.bg/', img: '/images/partners/devbg.png' },
  { url: 'https://www.oreilly.com/', img: '/images/partners/oreilly.png' },
  {
    url: 'https://www.digitalocean.com/',
    img: '/images/partners/digitalocean.png'
  },
  { url: 'https://graphicmama.com/', img: '/images/partners/graphicmama.png' },
  { url: 'https://mule.to/p26c', img: '/images/partners/stickermule.png' },
  { url: 'https://fss.fmi.uni-sofia.bg/', img: '/images/partners/fss.png' },
  { url: 'https://cryptodnes.bg/', img: '/images/partners/cryptodnes.png' },
  { url: 'https://www.jetbrains.com/', img: '/images/partners/jetbrains.png' }
];

export interface IScheduleDataItem {
  number: number;
  name: string;
  emoji: string;
  talks: Array<any>; // TODO: Set proper type;
}

export const SCHEDULE_DATA: { [key: string]: IScheduleDataItem } = {
  stageOne: {
    number: 1,
    name: 'Adventurer',
    emoji: '🧭',
    talks: [
      {
        speaker: {
          name: 'Ilian Iliev',
          avatar: '/images/speakers/IlianIliev.jpg',
          bio: 'Programming since 2003, in love with Python since 2009. Huge fan of Python for its simplicity to use, power and flexibility. Working as a software engineer at Lifesum, a digital health company based in Stockholm, Sweden. Spends his free time snorkeling, fishing, traveling and with his cute baby girl. :)'
        },
        title: 'Lessons Learned The Hard Way',
        description:
          'We all have made mistakes in the past, from tiny bugs to crashing production for hours. To think that it will never happen again is a delusion, but analyzing the root cause of those errors can help us avoid them in the future.',
        time: '14:00 - 15:00'
      },
      {
        speaker: {
          name: 'Radoslav Stankov',
          avatar: '/images/speakers/RadoslavStankov.jpg',
          bio: 'Full stack developer with 19+ years of experience, currently he is head of engineering at Product Hunt. Enjoys blogging and solving problems.'
        },
        title: 'Eliminating noise in your code',
        description:
          'If you have to scan the code like an interpreter when you try to understand it -> it means it is too complicated. Tips and tricks how not to get into this situation.',
        time: '15:00 - 16:00'
      },
      {
        speaker: {
          name: 'Darko Meszaroс',
          avatar: '/images/speakers/DarkoMeszaroc.jpg',
          bio: `Darko is a Senior Developer Advocate focusing on Central and Eastern Europe. He shares his passion and technological know-how with Engineers, Developers, and communities across the world. If it can be automated, Darko tries to do so. Most of his focus is towards DevOps and Management Tools, where automation, pipelines, and efficient developer tools is the name of the game – click less and code more so you do not repeat yourself - is what he says!

He has a long IT career in various areas, from retail, to government, to even large telcos. At AWS, Darko has worked as a Premium Support Engineer and as a Solutions Architect, which has given him first-hand experience with AWS customers and their AWS adventures, helping them learn the ways of the cloud!

As a passionate collector of old computers, he has a collection of computers and games and they came as an inspiration to tinker with computers as much as possible! Darko comes from Serbia, but has lived in Czech Republic, Malta, Ireland and now Germany!`
        },
        title: 'For the love of PROD, Test your Code!',
        description: `That’s it! You hit that git push command! The code it’s on it’s way to production. It’s an amazing piece of code, after all - you wrote it. But are you sure its good? Are you sure it will not break anything down the line? Okay, let’s talk about code testing strategies on AWS.`,
        time: '16:00 - 17:00'
      },
      {
        speaker: {
          name: 'Zhivko Petrov',
          avatar: '/images/speakers/ZhivkoPetrov.jpg',
          bio: `Zhivko is a C++ Software Engineer with 6+ years of professional experience.
Currently, he is working as a Robotics Software Engineer in the field of Perception Robotics.
The background project that he is most proud of is a 2D game engine.
His interests also include algorithms, code optimization, and game development.`
        },
        title: 'Batman and the algorithms',
        description: `Ever wondered how pathfinding is implemented in games? How about calculating the area of a complex shape with only using random numbers?
Join me and Batman as my sidekick where we explain the answers to those questions and many more using some cool demos.`,
        time: '17:00 - 18:00'
      },
      {
        speaker: {
          name: 'Georgi Stanev',
          avatar: '/images/speakers/GeorgiStanev.jpg',
          bio: 'High‐performing, strategic‐thinker with more than 12 years of software development experience in various programming languages and tools. Georgi is a technical leader and full-stack developer with strong expertise in Mobile Development iOS/Android/Flutter but also with great knowledge in Bаckend Development PHP/Java. He worked on many commercial projects in various business domains such as trading platforms, network monitoring systems, reporting systems and more.'
        },
        title: 'Building scalable, maintainable and robust apps in Flutter',
        description:
          'State management is one of the hottest topics in the Flutter community. If you want the app you are building to be robust, scalable, and maintainable, you need to use a neat architecture. In this talk, you will learn how to achieve all of the above by using rx_bloc as a state management solution.',
        time: '18:00 - 19:00'
      },
      {
        speaker: {
          name: 'Dmitry Vinnik',
          avatar: '/images/speakers/DmitryVinnik.jpg',
          bio: 'Dmitry is an Open Source Developer Advocate at Facebook, where he focuses on projects in the mobile space. His goal is to help developers be more productive through open source. Dmitry continues being a developer-first, so he applies the same mindset and passion when working on open source projects, creating new educational content, or giving conference talks. Dmitry has a background in medicine and bioinformatics, but these days he is more interested in understanding developer wellness and work-life balance.'
        },
        title: 'Impostor Syndrome: How to Overcome Your Inner Roadblock',
        description: `Do you ever wonder if you are good enough? If so, then you probably suffer from an Impostor Syndrome.
In this talk, we discuss how to identify and to overcome these self-imposed roadblocks to allow you to appreciate yourself and your voice.`,
        time: '19:00 - 20:00'
      }
    ]
  },
  stageTwo: {
    number: 2,
    name: 'Magician',
    emoji: '🧙',
    talks: [
      {
        speaker: {
          name: 'Martin Chaov',
          avatar: '/images/speakers/MartinChaov.jpg',
          bio: (
            <>
              <i>Who are you?</i> - Martin Chaov
              <br />
              <i>Why do you want people to know about you?</i> - I don't want
              people to know about me. I want people to do good software.
              <br />
              <i>Who's your favorite member of One Direction?</i> - Is this
              something solved with Dijkstra shortest path algorithm?
              <br />
              <br />
              <br />I don't take myself too seriously. I am a tech enthusiast.
              Most of my sentient life I have tinkered with tech. In IT I've
              done almost every job imaginable. I was doing cables, PC
              assemblies, IT support, domain controllers, networks, ISP, graphic
              design, software development… And I am equally bad at everything,
              meaning I have a lot of hands-on experience to share :)
            </>
          )
        },
        title: 'Performance 101',
        description: `Everything you know about software performance is probably wrong…

Performance is a business metric. Yes - how fast your website or service is loading/responding is a business requirement.

In this talk I am going to cover:
- What, why, and how should be measured?
- What are the possible root causes for poor performance?`,
        time: '14:00 - 15:00'
      },
      {
        speaker: {
          name: 'Ivaylo Bachvarov',
          avatar: '/images/speakers/IvayloBachvarov.jpg',
          bio: `CTO at HackSoft, а co-founder of HackBulgaria, a passionate developer using Django and React. His background includes DevOps, teaching, and speaking at community events.

Ivaylo Bachvarov has been in the industry for more than 8 years and surely can share many diverse interests as IoT, automation, and leadership. Last but not least, he is an awesome father who likes to spent time with his lovely little girl.`
        },
        title: 'End to End Automation from a Developer Standpoint',
        description: `In the talk I will cover:

- End to end automation for web based platforms.
- Where to start from?
- Difficulties and challenges.
- Best practices and things we learned the hard way.
- Automation and CI.
- Visual regression tests.
- How to do stress testing?`,
        time: '15:00 - 16:00'
      },
      {
        speaker: {
          name: 'Filareta Yordanova',
          avatar: '/images/speakers/FilaretaYordanova.jpg',
          bio: `Her career has started in an outsourcing company, which helped her acquire broad technical skills. Moving to an internship in Google, followed by working in a small startup and larger enterprise companies.
She is curious to explore new technologies and programming paradigms. Filareta has diverse experience - from research projects, utilizing signal processing and ML models, to building event-driven architectures.`
        },
        title: 'Making Sense of Stream Processing',
        description:
          'Structuring data as a stream of events is nothing new. With the advent of open source projects such as Apache Kafka, Spark Streaming and Kafka Streams, stream processing is gaining popularity in software projects. This model can be used to represent nearly every business activity we care to analyze.',
        time: '16:00 - 17:00'
      },
      {
        speaker: {
          name: 'Yosif Yosifov',
          avatar: '/images/speakers/YosifYosifov.jpg',
          bio: "Yosif is an engineering manager especially interested in building cloud products and distributed systems. He loves scaling teams, processes, and systems. Has a can-do attitude, but humble. He's a people person and would always take one for the team. Deep down he's an engineer at heart."
        },
        title: 'The art of scaling an Engineering Organization',
        description: `How do you evolve an engineering organization’s growth from 3 to 30 people, and above? I’ll share how we tackled:

- Evolution of different engineering organization designs
- Doubling team size, but try to stay productive in hypergrowth
- Scale processes and structure, yet foster innovation`,
        time: '17:00 - 18:00'
      },
      {
        speaker: {
          name: 'Georgi Kostadinov',
          avatar: '/images/speakers/GeorgiKostadinov.jpg',
          bio: "Georgi, a Head of AI & CTO at Imagga and Kelvin Health, has more than 10 years of experience in research and development of various machine learning algorithms for image and video understanding and over 15 years of experience in developing, deploying and managing complex software architectures. He is the key person responsible for the development of Imagga's core AI technology, as well as fine-tuning its performance and specialization for a variety of practical use-cases."
        },
        title:
          'Visual Attention is All You Need: Introduction to Visual Transformers',
        description: 'TO BE ANNOUNCED',
        time: '18:00 - 19:00'
      },
      {
        speaker: {
          name: 'Ell Marquez & Nicole Fishbein',
          avatar: '/images/speakers/EllMarquez&NicoleFishbein.jpg',
          bio: (
            <>
              <b>Ell Marquez</b>, advocate for Hacking Is Not A Crime and
              Operation Safe Escape has traveled the world for the last five
              years, educating security practitioners on subjects from on-prem
              infrastructure to the cloud and everything in between.
              <br />
              <br />
              <b>Nicole Fishbein</b> has spent the last six years entrenched in
              the security world as part of the IDF and currently discovering
              new malware as a part of the Intezer research team. Nicole's
              research contributions have led to the discovery of previously
              undetected malware and attack techniques.
            </>
          )
        },

        title: 'Missing Piece of Cloud Security',
        description:
          'With attacks to cloud environments in the first quarter of 2020 increasing 250%, prevention and detection have become the center focus for organizations. These concerns stem from an overwhelming amount of services and tools offered by cloud providers.',
        time: '19:00 - 20:00'
      }
    ]
  },
  stageThree: {
    number: 3,
    name: 'Visioner',
    emoji: '🦸️',
    talks: [
      {
        speaker: {
          name: 'Nikolay Angelov',
          avatar: '/images/speakers/NikolayAngelov.jpg',
          bio: `Nikolay Angelov is a blockchain developer, public speaker and blogger. As a self-taught coder and enthusiast for all newly brewing tech, Nikolay, nicknamed Niki, began programming in 2007 when he wrote his first mIRC script.
He has since mastered multiple programming technologies and participated in creating blockchain solutions for property giant Emaar and numerous mainnet projects. At present, Niki is leading the Blockchain development team at Nexo – a leading digital finance institution.
He has lectured and led workshops at various blockchain conferences, reaching thousands of attendees each year, in addition to teaching blockchain courses in Singapore and the Philippines.
Niki hopes to introduce his own innovations to blockchain. While he figures out exactly what they will be, he is dedicating his free time to gaming and ruminating on different technical and blockchain-related topics via his blog.`
        },
        title: 'Talk: Facing the Blockchain Challenges',
        description: `While Blockchains keep on getting popular and are awesome, there are still challenges that we need to solve before being able to handle mass adoption. In this talk we will dive into some of the Challenges that developers, protocols and services face while their business is related to Bitcoin and Ethereum and we will see some of the possible solutions for these challenges.
● How scalable are thеse networks?
● Are there privacy issues and should the day-to-day users be worried?
● Can we solve all the problems without risking the power of Decentralisation?`,
        time: '14:00 - 15:00'
      },
      {
        speaker: {
          name: 'Atanas Kuzmanov',
          avatar: '/images/speakers/AtanasKuzmanov.jpg',
          bio: `A passionate software engineer with over 10 years of experience.

Studied and graduated at the University of Essex.

Lived for 10 years in the United Kingdom, 5 of which spent working for the BBC.

Has successfully led large-scale projects involving cross-domain collaboration over high performance critical applications with clients worldwide.

Constantly lead on a path of exciting challenges by an everlasting passion for technology.

Other interests include: Music, Travelling, Model cars, and more...`
        },
        title: 'API-First! Contract-First! Make API development great again!',
        description:
          'For the very first time - AUTOMATIC OpenAPI ENDPOINT VALIDATION! These so-called OpenAPI documents can help you AUTOMATICALLY VALIDATE YOUR APIs and also serve as a contract, generate clients, servers, documentation, and much more…',
        time: '15:00 - 16:00'
      },
      {
        speaker: {
          name: 'Bogdana Boncheva',
          avatar: '/images/speakers/BogdanaBoncheva.jpg',
          bio: 'Bogdana Boncheva is an Agile Project Manager at heart and in reality. She is passionate about organising product delivery processes that are easy to follow and bringing the best out of people. She and her teams have started using OKRs 2 years ago and have been fearless ever since. Bogdana has lived in China and speaks Chinese, which she considers easier than any programming language.'
        },
        title: 'Be Fearless: achieve your goals with OKRs',
        description:
          "Are you achieving your most important goals? Are your great ideas making it to the finish line? Objectives & Key Results is the goal setting practice that helps teams at the Financial Times turn ideas into products. Come and find out how you can use OKRs to enhance yours and your team's achievements.",
        time: '16:00 - 17:00'
      },
      {
        speaker: {
          name: 'Ivan Vasilev',
          avatar: '/images/speakers/IvanVasilev.jpg',
          bio: 'Having worked on software, electronics and communications in the past 20 years, Ivan is fascinated by all things technical. You can often find him developing a well-being application in the wee hours of the night, troubleshooting a three-phase, remotely controlled electrical motor ten meters above a pond the next day and containerizing 30-year old mainframe software by next week.'
        },
        title: 'Why go RISC-V-y?',
        description: `You've interacted with several hundreds processors today already - from tiny ones in coffee machines to huge behemoths in data centers. They come in different forms and capabilities, but are all closed designs that you just use.
RISC-V changes that. Join me to understand how and why this is cool.`,
        time: '17:00 - 18:00'
      },
      {
        speaker: {
          name: 'Subby Angelov',
          avatar: '/images/speakers/SubbyAngelov.jpg',
          bio: 'Some 20 years ago, Subby started as a developer. Now he leads 9 teams with more than 80 people. In his leadership practice, he had to solve many different cases involving many different people. The experience he gained is worth sharing. Subby believes that the best way you can do good to someone is by sharing what you know. He believes in shortcuts and speed lines. He is a passionate speaker and enthusiastic to help people achieve more.'
        },
        title: 'How to be a conscious leader?',
        description:
          'Leadership is a skill. It is not necessary to learn it the hard way. Knowing the answers to several important questions makes it a lot easier. Come to find out why some are better leaders then others? Practical examples of good leadership. And ask yourself whether you would be a good leader?',
        time: '18:00 - 19:00'
      },
      {
        speaker: {
          name: 'Elena Georgieva',
          avatar: '/images/speakers/ElenaGeorgieva.jpg',
          bio: 'Elena Georgieva is currently Technical Director at the Financial Times. Before that, for the last 2 years, she has been leading the Data Platform Team based in Sofia, Bulgaria as a Principal Engineer. Elena is a software professional with a focus on Data Management and Applications with broad technology experience from relational databases to Big Data solutions with 20 years of experience in the industry. Last 6 years she has been specializing in platforms for telemetry storage and analysis of product usage.'
        },
        title: 'On Data: Sky above, sand below, peace within',
        description:
          'An exciting technology time travel with bites from reality, arts, and music. Hear from a data veteran how data architecture, data science, and data culture evolved over time to reach 2021 with the latest paradigms of Data Lake House and Data Democratisation.',
        time: '19:00 - 20:00'
      }
    ]
  }
};
