import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    color: white;
    font-size: 1.25rem;
    font-family: 'Nunito', sans-serif;
  }

  h1,h2,h3,h4,h5,h6 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-start: 0;

    font-weight: 600;
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #adadac1a;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    opacity: 0.3;
    background: #ed8e00;
  }
`;

export const QuestionBubbleToggler = styled.div`
  right: 2rem;
  bottom: 2rem;
  position: fixed;

  cursor: pointer;
  user-select: none;

  font-weight: 700;
  font-size: 1.5rem;

  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.backgroundOrange};

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s linear;
  animation: flip 0.3s linear;

  &:hover {
    transform: scale(1.2);
    box-shadow: 0px 0px 15px ${({ theme }) => theme.backgroundOrange};
  }

  @keyframes flip {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
`;

export const QuestionBubble = styled.div`
  right: 2rem;
  bottom: 6rem;
  position: fixed;

  padding: 1rem;
  box-sizing: border-box;

  max-width: 250px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.backgroundOrange};

  transform-origin: bottom right;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? 'scaleY(1)' : 'scaleY(0)'};

  a {
    color: white;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: -0.375rem;
    right: 1.1rem;

    transform: rotate(-45deg);

    border-style: solid;
    border-width: 0 0 0.75rem 0.75rem;
    border-color: ${({ theme }) => theme.backgroundOrange};
  }
`;

export default GlobalStyle;
