// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

import { SENTRY_DSN, SENTRY_ENVIRONMENT } from 'libs/constants';

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://c1f8f52891dd4b8ca804c219f8ace1e5@o56043.ingest.sentry.io/5973501',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment: SENTRY_ENVIRONMENT
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
