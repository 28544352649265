import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { Provider as AuthProvider, useSession } from 'next-auth/client';
import { signIn } from 'next-auth/client';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useState, ReactElement, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';

import GlobalStyle, {
  QuestionBubble,
  QuestionBubbleToggler
} from 'styles/globalStyles';

import { THEME } from 'libs/constants';

const HackConfLive = ({
  Component,
  pageProps
}: AppProps & { Component: AppProps['Component'] & { auth: boolean } }) => {
  const [isQuestionBubbleOpen, setIsQuestionBubbleOpen] = useState(false);

  return (
    <AuthProvider session={pageProps.session}>
      <ThemeProvider theme={THEME}>
        <Head>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap"
            rel="stylesheet"
          />
          <title>HackConf 2021</title>
          <meta
            name="description"
            content="HackConf is Bulgaria’s premier “for developers, by developers“ motivational and knowledge sharing event."
          />
          <meta property="og:image" content="/images/ogImage.png" />
          <link rel="icon" href="/favicon.png" />
        </Head>
        <Normalize />
        <GlobalStyle />
        {Component.auth ? (
          <Auth>
            <Component {...pageProps} />
          </Auth>
        ) : (
          <Component {...pageProps} />
        )}

        <QuestionBubble isOpen={isQuestionBubbleOpen}>
          Do you want to ask a question? <br />
          If you're a <b>FULL ACCESS</b> or a <b>SUPERFAN</b> ticket holder,
          check your email. We've sent you an invitation for the Discord server.
          <br />
          <br />
          <i>
            If you want to upgrade your ticket, apply promo code:{' '}
            <b>ALMOSTFREE</b> to your purchase, and you won't pay the amount
            you've already paid.
          </i>
          <br />
          <br />
          Do you have additional questions?
          <br />
          Drop us a line at{' '}
          <a href="mailto:team@hackconf.bg">team@hackconf.bg</a>
        </QuestionBubble>
        <QuestionBubbleToggler
          key={isQuestionBubbleOpen.toString()}
          onClick={() => setIsQuestionBubbleOpen((prevValue) => !prevValue)}>
          {isQuestionBubbleOpen ? 'x' : '?'}
        </QuestionBubbleToggler>
      </ThemeProvider>
    </AuthProvider>
  );
};

const Auth = ({ children }: { children: ReactElement }) => {
  const [session, loading] = useSession();
  const isUser = !!session?.user;

  useEffect(() => {
    if (loading) {
      return;
    } // Do nothing while loading

    if (!isUser) {
      Sentry.configureScope((scope) => scope.setUser(null));
      signIn();
    } // If not authenticated, force log in
  }, [isUser, loading]);

  if (isUser) {
    Sentry.setUser({ email: _.get(session, 'user.email') as string });

    return children;
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <div>Loading...</div>;
};

export default HackConfLive;
